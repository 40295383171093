import { useEffect } from '@wordpress/element';

/**
 * This component is used to notify the parent window of <Checkout /> that the order
 * is complete. This allows us to break out of the iframe.
 */
const CheckoutConfirm = () => {
    const params = new URLSearchParams(window.location.search);
    const orderNumber = params.get('orderNumber');

    useEffect(() => {
        window.parent.postMessage({ orderNumber }, '*');
    }, []);

    return null;
};

export default CheckoutConfirm;
