import { useState, useEffect } from '@wordpress/element';

export const useInitialURLParams = () => {
    const [params, setParams] = useState({ orderType: null });

    useEffect(() => {
        const updateParams = () => {
            const searchParams = new URLSearchParams(window.location.search);
            const orderType = searchParams.get('orderType');
            setParams({ orderType: orderType || null });
        };

        // Initial update
        updateParams();

        // Add event listener for popstate event
        window.addEventListener('popstate', updateParams);

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('popstate', updateParams);
        };
    }, []);

    return params;
};
